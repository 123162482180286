// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"07783c8f1bc764624a4e8ccd1676c5652fab6b6f"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "/espresso-assets";import * as Sentry from '@sentry/nextjs';
import { sentrySharedOptions } from 'sentry-shared';

Sentry.init({
  ...sentrySharedOptions,
});
